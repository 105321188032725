<template>
    <div>
        <h2>{{ $t("lieu.residence_principale") }}</h2>
        <HorseResidence :horse_id="horse_id" />
        <HorseLieuStationnement v-if="hasTiersAccess" :horse_id="horse_id" />

        <div>
            <h2></h2>
            <div class="box">
                <div class="row">
                    <div class="col">
                        <label for="lieu">{{ $t('mouvement.lieu_mouvement') }}</label>
                        <e-select
                            v-model="selected_lieu"
                            id="mouv_resid"
                            track-by="lieu_id"
                            label="lieu_label"
                            :placeholder="labelTitle"
                            :deselectLabel="deselectLabel"
                            :selectedLabel="selectedLabel"
                            :selectLabel="enter_to_select"
                            :options="lieux"
                            :searchable="true"
                            :show-labels="false"
                            :allow-empty="false"
                        >
                            <template slot="option" slot-scope="{ option }">{{ option.lieu_label }}</template>
                            <template slot="singleLabel" slot-scope="{ option }">{{ option.lieu_label }}</template>
                            <template slot="noOptions">{{ $t('global.list_empty') }}</template>
                        </e-select>
                    </div>

                    <div class="col">
                        <label for="date_start">{{ $t('global.date_debut') }}</label>
                        <e-datepicker id="date_start" v-model="date_start"></e-datepicker>
                    </div>

                    <div class="col">
                        <label for="date_end">{{ $t('global.date_fin') }}</label>
                        <e-datepicker id="date_end" v-model="date_end"></e-datepicker>
                    </div>

                    <div class="col">
                        <label>&nbsp;</label>
                        <button class="btn btn-primary btn-block" @click.prevent="loadPresenceDays">{{ $t('global.rechercher') }} <font-awesome-icon v-if="processing_presence" :icon="['fas', 'spinner']" pulse /></button>
                    </div>
                </div>
                <div v-if="presence_days" class="row mt-2">
                    <div class="col-12 text-center">
                        <label>{{ $t('mouvement.jour_presence') }} : {{ presence_days }}</label>
                    </div>
                </div>
            </div>
        </div>

        <div v-show="main_vue">
            <h2>{{ $t("mouvement.historique_mouvement" )}}</h2>
            <div class="box">
                <CustomTable
					ref="mouv_table"
                    :id_table="id_table"
                    primaryKey="mouvement_id"
                    selectMode="single"
					:transformer="['MouvementTransformer', 'withHorseAndType']"
                    :busy.sync="table_busy"
                    :display_action_button="!horseFicheReadOnly"
                    :checkboxes="!horseFicheReadOnly"
					:date-filter="dateFilter"
					:base-filters="filters"
					:persistDateFilter="true"
                />
            </div>
        </div>
    </div>
</template>


<script type="text/javascript">
    import Mouvements from "@/mixins/Mouvements.js"
    import Navigation from "@/mixins/Navigation.js"
    import Lieux from "@/mixins/Lieux.js"

	export default {
		name: "historiqueMouvements",
		mixins: [
            Mouvements,
            Navigation,
            Lieux
        ],
        props: [
            'horse_id',
        ],
        data () {
			return {
                id_table: "mouvements_horse",
                mouvements: null,
                main_vue: true,
                events_tab: {
                    'HistoriqueMouvement::editResidence': this.editResidence,
                    'HistoriqueMouvement::updateMouvements': this.reloadMouvementsListe,
                    'TableAction::DeleteMouvements': this.handleDeleteMouvements
                },

                /* Configuration du tableau : */
                table_busy: true,
				dateFilter: {
					column: 'mouvement_date',
				},
                deselectLabel: this.getTrad("global.press_enter_to_remove"),
                selectedLabel: this.getTrad("global.selected_label"),
                enter_to_select: this.getTrad("global.press_enter_to_select"),
                labelTitle: this.getTrad("global.residences"),
                selected_lieu: null,
                lieux: [],
                date_start: new Date(),
                date_end: new Date(),
                presence_days: null,
                processing_presence: false
			}
		},
        created() {
            if(this.getConfig('startDate' + this.id_table)) {
                this.dateFilter.start = new Date(this.getConfig('startDate' + this.id_table))
            }

            if(this.getConfig('endDate' + this.id_table)) {
                this.dateFilter.end = new Date(this.getConfig('endDate' + this.id_table))
            }

            this.loadLieu()
        },
		methods: {
            editResidence(status) {
                this.main_vue = !status
			},
			
			reloadMouvementsListe() {
				this.$refs.mouv_table.refreshTable()
				this.$refs.intra_location.refresh()
			},

			async handleDeleteMouvements(mouvements) {
				await this.applyDeleteMouvements(mouvements)
				this.reloadMouvementsListe()
			},

            async loadLieu() {
                this.lieux = await this.getLieux(false)
            },

            async loadPresenceDays() {
                if(!this.selected_lieu) {
                    return false
                }
                this.processing_presence = true
                this.presence_days = await this.getPresenceDays(this.horse_id, this.selected_lieu.lieu_id, this.date_start, this.date_end)
                this.processing_presence = false
            }
        },
        computed: {
            hasTiersAccess() {
                return this.$store.state.userAccess.hasFacturationAccess || this.$store.state.userAccess.hasBreedingAccess
            },
            horseFicheReadOnly() {
                return this.$store.state.horseFicheReadonly
			},
			filters() {
				return {
					is_this_horse: {
						column: 'mouvement_horse',
						operator: 'isEqualTo',
						value: this.$sync.replaceWithReplicated('horse', this.horse_id)
					},
                    is_mouvement: {
                        column: 'mouvement_type',
                        operator: 'isNotEqualTo',
                        value: 5
                    }
				}
			}
        },
		components: {
			CustomTable: () => import('GroomyRoot/components/Table/CustomTable'),
			HorseResidence: () => import('@/components/ShutterScreens/Horse/HorseResidence'),
            HorseIntraLocation: () => import('@/components/Horse/HorseIntraLocation'),
			HorseLieuStationnement: () => import('@/components/Horse/HorseLieuStationnement')
		}
	}
</script>
